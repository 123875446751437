.footer {
  background-color: var(--dark-color);
  color: var(--white);
  padding-top: 70px;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 50px;
}

.footer-logo {
  flex: 1;
  min-width: 250px;
  margin-bottom: 30px;
}

.footer-logo h2 {
  font-size: 2.2rem;
  margin-bottom: 15px;
  font-weight: 800;
  letter-spacing: 1px;
}

.footer-logo p {
  color: #fff;
  font-weight: 500;
  font-size: 1.1rem;
}

.footer-links {
  flex: 2;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer-section {
  min-width: 200px;
  margin-bottom: 30px;
}

.footer-section h3 {
  font-size: 1.3rem;
  margin-bottom: 20px;
  position: relative;
  padding-bottom: 10px;
  font-weight: 700;
  color: #fff;
  letter-spacing: 0.5px;
}

.footer-section h3:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 3px;
  background-color: var(--primary-color);
}

.footer-section ul {
  list-style: none;
}

.footer-section ul li {
  margin-bottom: 12px;
}

.footer-section ul li a {
  color: #fff;
  text-decoration: none;
  transition: var(--transition);
  font-weight: 500;
  font-size: 1.5rem;
}

.footer-section ul li a:hover {
  color: var(--primary-color);
  padding-left: 5px;
}

.footer-section p {
  color: #fff;
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 1.5rem;
}

.social-icons {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

.social-icons a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  color: var(--white);
  transition: var(--transition);
}

.social-icons a:hover {
  background-color: var(--primary-color);
  transform: translateY(-3px);
}

.footer-bottom {
  text-align: center;
  padding: 20px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.footer-bottom p {
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
  }
  
  .footer-links {
    flex-direction: column;
  }
  
  .footer-section h3 {
    font-size: 1.2rem;
  }
  
  .footer-section ul li a,
  .footer-section p {
    font-size: 1rem;
  }
} 