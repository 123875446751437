.cta {
  background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9));
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  color: var(--white);
  text-align: center;
}

.cta-content {
  max-width: 600px;
  margin: 0 auto;
}

.cta h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-weight: 700;
  text-transform: uppercase;
}

.cta p {
  font-size: 1.2rem;
  margin-bottom: 40px;
}

.cta-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.cta-form input,
.cta-form select {
  padding: 15px;
  border-radius: 4px;
  border: none;
  font-size: 1rem;
}

.cta-form button {
  margin-top: 10px;
  padding: 15px;
  font-size: 1.1rem;
}

@media (max-width: 768px) {
  .cta h2 {
    font-size: 2rem;
  }
  
  .cta p {
    font-size: 1rem;
  }
} 