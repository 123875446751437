.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding: 20px 0;
  transition: var(--transition);
}

.navbar.scrolled {
  background-color: rgba(0, 0, 0, 0.9);
  padding: 15px 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

.logo a {
  color: var(--white);
  text-decoration: none;
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: 1px;
}

.nav-menu {
  display: flex;
  list-style: none;
  align-items: center;
}

.nav-menu li {
  margin-left: 30px;
}

.nav-menu li a {
  color: var(--white);
  text-decoration: none;
  font-weight: 600;
  font-size: 1rem;
  transition: var(--transition);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.nav-menu li a:hover {
  color: var(--primary-color);
}

.cta-button a {
  background-color: var(--primary-color);
  padding: 10px 20px;
  border-radius: 4px;
  color: var(--white);
}

.cta-button a:hover {
  background-color: #ff3333;
  color: var(--white);
}

.mobile-menu-icon {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
  cursor: pointer;
}

.mobile-menu-icon span {
  display: block;
  height: 3px;
  width: 100%;
  background-color: var(--white);
  transition: var(--transition);
}

@media (max-width: 768px) {
  .mobile-menu-icon {
    display: flex;
  }

  .nav-menu {
    position: fixed;
    top: 0;
    right: -100%;
    width: 70%;
    height: 100vh;
    flex-direction: column;
    background-color: var(--dark-color);
    padding-top: 80px;
    transition: var(--transition);
    align-items: flex-start;
    padding-left: 40px;
  }

  .nav-menu.active {
    right: 0;
  }

  .nav-menu li {
    margin: 15px 0;
  }
  
  .cta-button {
    margin-top: 20px;
  }
} 