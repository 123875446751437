.hero {
  height: 100vh;
  background: linear-gradient(135deg, #333 0%, #111 100%);
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--white);
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.hero-content {
  position: relative;
  z-index: 1;
  max-width: 800px;
  padding: 0 20px;
  padding-top: 60px;
}

.hero h1 {
  font-size: 4rem;
  font-weight: 800;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 1.2;
}

.hero h2 {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 20px;
  color: var(--primary-color);
}

.hero p {
  font-size: 1.2rem;
  margin-bottom: 40px;
  font-weight: 400;
}

.hero-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 40px;
}

.gym-location {
  margin-top: 30px;
}

.facility-text {
  font-size: 2rem;
  font-weight: 800;
  margin-bottom: 10px;
  text-transform: uppercase;
  line-height: 1.2;
}

.gym-logo {
  max-width: 150px;
  height: auto;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.gym-logo:hover {
  transform: scale(1.05);
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.3);
}

@media (max-width: 768px) {
  .hero-content {
    padding-top: 80px;
  }
  
  .hero h1 {
    font-size: 2.5rem;
  }
  
  .hero h2 {
    font-size: 1.5rem;
  }
  
  .hero p {
    font-size: 1rem;
  }
  
  .hero-buttons {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
  
  .gym-logo {
    max-width: 120px;
  }
}

@media (max-width: 480px) {
  .hero-content {
    padding-top: 100px;
  }
  
  .hero h1 {
    font-size: 2rem;
  }
  
  .hero h2 {
    font-size: 1.2rem;
  }
} 